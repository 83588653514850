
import { computed } from 'vue';

// icons
import { personCircle, compass, informationCircle, mail, call, location, bag, alertCircle } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner,
        IonCard, IonCardHeader, IonCardContent, IonCardTitle,
        IonSegment, IonSegmentButton, IonLabel, IonAvatar,
        IonButtons, IonButton, IonSlide, IonSlides, IonIcon, } from '@ionic/vue';
import ProductCard from "@/components/ProductCard.vue";
import CartButton from "@/components/CartButton.vue";
import LogoImg from "@/components/LogoImg.vue";
import Slides from "@/components/Slides.vue";

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { utils } from '@/composables/utils';

export default {
  name: 'HomePage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonSpinner,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle,
                IonSegment, IonSegmentButton, IonLabel, IonAvatar,
                IonButtons, IonButton, IonSlide, IonSlides, IonIcon,
                ProductCard, CartButton, LogoImg, Slides, },
  setup() {
    // methods
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { openBrowser } = utils();

    // state variables
    const loading = computed(() => store.state.loadingAppPublicData);
    const banners = computed(() => store.state.homeBanners);
    const featuredProducts = computed(() => store.getters.featuredProducts);
    const latestProducts = computed(() => store.getters.latestProducts);
    const allProductCategories = computed(() => store.state.allProductCategories);
    const companyInfo = computed(() => store.state.companyInfo);

    const categorySegmentChanged = (e: any) => {
      if (e.target.value) {
        router.push({ name: 'ProductListPage', query: { category: e.target.value } }); // go to product page and pre-select category
      }
    }

    return {
      t,

      categorySegmentChanged, openBrowser,

      // icons
      personCircle, compass, informationCircle, mail, call, location, bag, alertCircle, 
      
      // variables
      locale, loading,
      companyInfo,
      featuredProducts, latestProducts,
      allProductCategories,
      banners,
    }
  },
}
